<script>
import ModularCard from "@/components/molecules/ModularCard.vue";

export default {
  name: "OneTimeSuccessTemplate",
  components: { ModularCard },
  data() {
    return {
      thankYouCard: {
        0: {
          type: "title",
          title: "Cadastro atualizado com sucesso!",
        },
        1: {
          type: "image",
          image: "dojosei-screen",
        },
        2: {
          subtitle: "Agora o seu sensei já tem acesso aos seus dados.",
          type: "subtitle",
        },
        3: {
          type: "subtitle",
          subtitle:
            "Entraremos em contato assim que o App do Aluno estiver disponivel.",
        },
      },
    };
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <span class="section-container message-template" style="padding: 16px">
    <modular-card
      :cardModules="thankYouCard"
      customStyle="min-height: calc((var(--vh, 1vh) * 100) - 32px) !important; justify-content: flex-start; gap: 32px;"
    />
  </span>
  <!-- text2="Para saber mais sobre o Dojosei, visite nosso website." -->
</template>

<style lang="scss">
.message-template {
  min-height: calc((var(--vh, 1vh) * 100)) !important;
  max-height: calc((var(--vh, 1vh) * 100)) !important;
}
</style>
