<script>
import OneTimeSuccessTemplate from "@/components/templates/OneTimeSuccessTemplate.vue";

export default {
  name: "OneTimeSuccessPage",
  components: { OneTimeSuccessTemplate },
  data() {
    return {};
  },
  methods: {
    closeWindow() {
      window.close();
    },
  },
};
</script>

<template>
  <one-time-success-template @click="closeWindow" />
</template>

<style lang="scss" scoped></style>
